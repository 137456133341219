import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Intro from "../components/website/Intro"
import CareersList from "../components/website/careers/index"

export default function Careers({ data }) {
  return (
    <Layout>
      <SEO title="Careers" />
      {data.allStrapiCareer.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Intro
            title={node.careers_intro.intro_title}
            text={node.careers_intro.intro_description}
            img={node.careers_intro.Intro_hero.publicURL}
          />
          <CareersList />
        </React.Fragment>
      ))}
    </Layout>
  )
}

export const query = graphql`
  query Careers {
    allStrapiCareer {
      edges {
        node {
          careers_intro {
            id
            intro_title
            intro_description
            intro_color
            Intro_hero {
              publicURL
            }
          }
        }
      }
    }
  }
`
