import React from "react"
import { Container, Grid } from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"

function removeObjectWithId(arr, id) {
  const objWithIdIndex = arr.findIndex(obj => obj.id === id)

  if (objWithIdIndex > -1) {
    arr.splice(objWithIdIndex, 1)
  }

  return arr
}

function Careers() {
  const data = useStaticQuery(graphql`
    {
      allStrapiCareerListings {
        edges {
          node {
            id
            careers_title
            careers_description
            careers_link
          }
        }
      }
    }
  `)

  const positions = data?.allStrapiCareerListings?.edges.map(({ node }) => node)

  const positionRemovedId = "Career-listings_12"

  const positionsShowUp = removeObjectWithId(positions, positionRemovedId)

  return (
    <>
      <div className="blue-section white-text">
        <Container className="parallax">
          <Grid item md={8} sm={12} className="mx-auto parallax-text md:mb-40">
            {positionsShowUp && positionsShowUp.length > 0 && (
              <h3>Current job openings are listed below.</h3>
            )}
            {positionsShowUp && positionsShowUp.length > 0 ? (
              positionsShowUp.map((position, i) => {
                return (
                  <React.Fragment key={i}>
                    <Grid container className="mx-auto mt-10">
                      <div className="career-post">
                        <h5 className="orange-text mb-5">
                          {position.careers_title}
                        </h5>
                        <p>{position.careers_description}</p>
                        <a
                          href={`${position.careers_link}`}
                          target="_blank"
                          rel="noreferrer"
                          className="border-2 border-white border-solid	py-3 px-10 mb-10"
                        >
                          Apply Today
                        </a>
                        <hr />
                      </div>
                    </Grid>
                  </React.Fragment>
                )
              })
            ) : (
              <Grid container className="mx-auto mt-10">
                <div className="career-post my-10 py-10">
                  <h3>There are no current job openings.</h3>
                  <p>Please check back in the future.</p>
                  <hr />
                </div>
              </Grid>
            )}
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default Careers
